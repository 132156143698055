import Masonry from 'react-masonry-css';
import './AI.css';

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 1,
  500: 1
};

const AI = () => {
  const email = 'craig@tinybigideas.com';

  const handleClick = (subject: string) => {
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  };

  return (
    <div className="things">

      <div className="grid">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="grid"
          columnClassName="grid__column"
        >
          <div className="thing">
            <div className="thing-image padding">
              <img alt="Lead Capture & CRM Integration icon" src={process.env.PUBLIC_URL + '/images/lead-automation.png'} />
            </div>
            <div className="thing-content">
              <span className="thing-title">Lead Capture & CRM Integration</span>
              <p>Capture leads effortlessly and seamlessly integrate them into your CRM.</p>
            </div>
            <div className="thing-action">
              <a href="https://forms.gle/cciwea9w3xSxTYtj8" target="_blank" rel="noreferrer">
                Request a personalised demo
              </a>
            </div>
          </div>

          <div className="thing">
            <div className="thing-image padding">
              <img alt="Customer Support Management icon" src={process.env.PUBLIC_URL + '/images/support-automation.png'} />
            </div>
            <div className="thing-content">
              <span className="thing-title">Customer Support Management</span>
              <p>Provide exceptional customer service with AI-powered support automation.</p>
            </div>
            <div className="thing-action">
              <a href="https://forms.gle/F75iECRxJJu2enBe7" rel="noreferrer" target="_blank">
                Request a personalised demo
              </a>
            </div>
          </div>

          <div className="thing">
            <div className="thing-image padding">
              <img alt="Appointment Schedule Management icon" src={process.env.PUBLIC_URL + '/images/appointment-automation.png'} />
            </div>
            <div className="thing-content">
              <span className="thing-title">Appointment Schedule Management</span>
              <p>Simplify appointment scheduling and reduce no-shows with intelligent automation.</p>
            </div>
            <div className="thing-action">
              <a href="https://forms.gle/3XM476wUnEJzhhWc8" rel="noreferrer" target="_blank">
                Request a personalised demo
              </a>
            </div>
          </div>
        </Masonry>
      </div>
    </div>
  );
};

export default AI;
