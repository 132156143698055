import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate, NavLink } from 'react-router-dom';
import './index.css';
import linkedin from './assets/linkedin-icon.svg';
import AI from './pages/AI';
import Projects from './pages/Projects';
import ReactGA4 from 'react-ga4';

ReactGA4.initialize('G-7BYX3V70RG');
ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="page">
        <div className="vertical-align">
          <div className="content">
            <img className="avatar" width="100" alt="Craig Thomas profile" src={process.env.PUBLIC_URL + '/images/avatar.jpg'} />
            <h1>
              Craig Thomas{' '}
              <a href="https://www.linkedin.com/in/tinybigideas/" rel="noreferrer" target="_blank">
                <img alt="linkedin icon" width="30" src={linkedin} />
              </a>
            </h1>

            <p>
              AI Automation & Software Engineering.
            </p>

            <ul className="tabs">
              <li className="tab">
                <NavLink to="/ai">AI Automation</NavLink>
              </li>
              <li className="tab">
                <NavLink to="/projects">Past Projects</NavLink>
              </li>
            </ul>
          </div>
        </div>

        <Routes>
          <Route path="/" element={<Navigate to="/ai" />} />
          <Route path="projects" element={<Projects />} />
          <Route path="ai" element={<AI />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);
