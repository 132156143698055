export enum Category {
  WEB1 = 'web1',
  WEB2 = 'web2',
  WEB3 = 'web3',
  MISC = 'misc',
  AI = 'ai'
}

export interface DataItem {
  id: number;
  category: string;
  title: string;
  startdate: string;
  enddate?: string;
  location?: string;
  logourl?: string;
  href?: string;
  description: string;
  skills?: string;
}
