import './ListItem.css';
import { DataItem } from '../data/data.interface';
import { format } from 'date-fns';

interface Props {
  item: DataItem;
}

const formatDate = (date: string | undefined): string | undefined =>
  date ? format(new Date(date), 'MMM yyyy') : undefined;

const ListItem = (props: Props) => {
  return (
    <div className="thing">
      {props.item.logourl && (
        <div className="thing-image padding">
          <img
            alt={props.item.title}
            src={process.env.PUBLIC_URL + '/images/' + props.item.logourl}
          />
        </div>
      )}

      <div className="thing-content">
        <span className="thing-title">{props.item.title}</span>
        <small>
          {formatDate(props.item.startdate)} - {formatDate(props.item.enddate) ?? 'Present'} |{' '}
          {props.item.location}
        </small>

        <p>{props.item.description}</p>

        {props.item.skills && (
          <small className="thing-skills">
            <span className="highlight-colour">SKILLS:</span> {props.item.skills}
          </small>
        )}
      </div>
      {props.item.href && (
        <div className="thing-action">
          <a href={props.item.href} rel="noreferrer" target="_blank">
            Have a look-see
          </a>
        </div>
      )}
    </div>
  );
};

export default ListItem;
