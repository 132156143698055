import List from '../components/List';
import { Category } from '../data/data.interface';

const Projects = () => {
  return <>
    <List category={Category.WEB2} />
    <List category={Category.WEB1} />
    <List category={Category.MISC} />
  </>;
};

export default Projects;
