import './List.css';
import { useState } from 'react';
import { Category } from '../data/data.interface';
import ListItem from './ListItem';
import { getRows } from '../utilities/getRows';
import Masonry from 'react-masonry-css';

interface Props {
  category: Category;
}

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

const List = (props: Props) => {
  const [rows, setRows] = useState(getRows(props.category));

  return (
    <div className={'things ' + props.category}>
      <h4 className="things-header highlight-colour">{props.category} things</h4>

      <div className="grid">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="grid"
          columnClassName="grid__column"
        >
          {rows.map((row) => (
            <ListItem key={row.id} item={row}></ListItem>
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default List;
